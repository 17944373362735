const expandableColumnTreeMap = (data) => {
    console.log("tree:::DATA>>>", data);

    const tree = {};
    tree["parent_source_name"] = data?.final_output_variable_name || "Unknown";

    // Display the final_output_formula first
    let formulaString = data?.final_output_formula?.formula || "";
    if (typeof formulaString === 'string' && formulaString.trim()) {
        tree["parent_formula"] = formulaString;
    }

    tree["output"] = data?.final_output_formula?.output
    // ?.toLocaleString('en-IN', {


    //     minimumFractionDigits: 2,
    //     maximumFractionDigits: 2,
    // }),
    tree["children"] = [];

    const processChildrenInputs = (inputData, treeNode) => {
        inputData.forEach((inputItem) => {
            const nodeName = Object.keys(inputItem)[0];
            const nodeValue = Number(inputItem[nodeName]);

            let childNode = {
                source_name: nodeName, output: nodeValue?.toLocaleString('en-IN', {


                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
            };
            treeNode.children.push(childNode);
        });
    }
    // Recursive function to process the inputs
    const processInputs = (inputData, treeNode) => {

        inputData.forEach((inputItem) => {

            const nodeName = Object.keys(inputItem)[0];
            const nodeValue = inputItem[nodeName];

            let childNode = { source_name: nodeName, value: nodeValue };

            // Find the matching decision output for ElseJsonLogic_formula
            let matchedDecision = data.decisionOutputs.find((decisionItem) => {
                const decisionItemKeys = Object.keys(decisionItem);

                if (decisionItemKeys.includes(nodeName)) {
                    return decisionItem
                } else if (decisionItem?.feel_expression_formula?.variable === nodeName) {
                    return decisionItem
                }

            });
            if (matchedDecision) {

                childNode = {
                    ...childNode,
                    ruleIndex: matchedDecision?.ruleIndex,
                    rulePlan: matchedDecision?.rulePlan,
                    formula: matchedDecision?.feel_expression_formula?.formula || matchedDecision?.ElseJsonLogic_formula?.formula,
                    output: matchedDecision?.feel_expression_formula?.output.toLocaleString('en-IN', {


                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    }),
                    decision: {}
                };
                childNode.decision["decisionId"] = matchedDecision?.decisionId
                childNode.decision["decisionName"] = matchedDecision?.decisionName
                childNode.decision["decisionOutput"] = matchedDecision?.decisionOutput
                childNode.decision["decisionVersion"] = matchedDecision?.decisionVersion
                childNode.decision["rulePlan"] = matchedDecision?.rulePlan

                if (matchedDecision?.decisionInputs?.length > 0) {
                    childNode.decision["decisionInput"] = matchedDecision?.decisionInputs
                }

                // Process inputs recursively if available for feel_expression_formula or ElseJsonLogic_formula
                if (matchedDecision?.inputs?.length > 0) {
                    childNode["children"] = [];
                    processChildrenInputs(matchedDecision.inputs, childNode);
                } else if (matchedDecision?.ElseJsonLogic_formula?.inputs?.length > 0) {
                    childNode["children"] = [];
                    processInputs(matchedDecision.ElseJsonLogic_formula.inputs, childNode);
                } else if (matchedDecision?.feel_expression_formula?.inputs) {
                    childNode["children"] = [];
                    processChildrenInputs(matchedDecision?.feel_expression_formula?.inputs, childNode);
                } else {
                    childNode["children"] = [];
                }
            }
            treeNode.children.push(childNode);
        });
    };

    // Display ElseJsonLogic_formula if available
    let elseFormula = data?.decisionOutputs?.find((d) => d.ElseJsonLogic_formula);
    if (elseFormula) {
        let elseFormulaString = elseFormula?.ElseJsonLogic_formula?.formula || "";
        if (typeof elseFormulaString === 'string' && elseFormulaString.trim()) {
            // Add the ElseJsonLogic_formula as a child node of the main tree
            let elseFormulaNode = {
                source_name: data?.final_output_formula?.formula,
                formula: elseFormulaString,
                output: elseFormula?.ElseJsonLogic_formula?.output?.toLocaleString('en-IN', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
                children: [],
                decision: {}
            };
            elseFormulaNode.decision["decisionId"] = elseFormula?.decisionId
            elseFormulaNode.decision["decisionName"] = elseFormula?.decisionName
            elseFormulaNode.decision["decisionOutput"] = elseFormula?.decisionOutput
            elseFormulaNode.decision["decisionVersion"] = elseFormula?.decisionVersion

            if (elseFormula?.decisionInputs?.length > 0) {
                elseFormulaNode.decision["decisionInputs"] = elseFormula?.decisionInputs
            }

            // Process the inputs for ElseJsonLogic_formula recursively
            if (elseFormula?.inputs?.length > 0) {
                processInputs(elseFormula.inputs, elseFormulaNode);
            }
            // Add this node to the main tree's children
            tree.children.push(elseFormulaNode);
        }
    }



    // Process final_output_formula inputs recursively
    if (data?.final_output_formula?.inputs?.length > 0) {

        processInputs(data.final_output_formula.inputs, tree);
    } else if (data?.decisionOutputs) {
        // Process inputs from decisionOutputs if final_output_formula inputs are not available
        data.decisionOutputs.forEach((decision) => {
            if (decision?.feel_expression_formula?.inputs?.length > 0) {
                processInputs(decision.feel_expression_formula.inputs, tree);
            } else if (decision?.ElseJsonLogic_formula?.inputs?.length > 0) {
                processInputs(decision.ElseJsonLogic_formula.inputs, tree);
            }
        });
    }

    console.log("tree:::", tree);
    return tree;
};

export default expandableColumnTreeMap;
