// Package Imports
import { Button, Col, Input, Row, Upload, message, Form, Badge } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';

// Project Imports
import '../index.css'
import A8Utils from '../../../utils/A8Utils';
import { invoiceSummaryDataDummy } from '../GridInvoiceSummaryData';

const GridInvoiceSummaryCustomComponent = (props) => {

    const { component, value, isBuilder } = props;

    const { Dragger } = Upload;
    const FormItem = Form.Item;
    const [form] = Form.useForm();

    const fileTypes = [A8Utils.fileTypes.jpeg, A8Utils.fileTypes.png, A8Utils.fileTypes.pdf]
    const fileExtension = [A8Utils.fileExtensions.jpg, A8Utils.fileExtensions.png, A8Utils.fileExtensions.pdf]

    const [ready, setReady] = useState(false);
    const [fileList, setFileList] = useState([])
    const [responseData, setResponseData] = useState({});
    const [formInvoiceFields] = useState({
        invoiceSummaryList: component.invoiceSummaryList,
        invoiceSummaryApiUrl: component.invoiceSummaryApiUrl,
        invoiceSummaryApiUrlMethod: component.invoiceSummaryApiUrlMethod,
        invoiceSummaryApiUrlParamsList: component.invoiceSummaryApiUrlParamsList,
        invoiceSummaryApiResponseDataKey: component.invoiceSummaryApiResponseDataKey,
        invoiceDownloadApiUrl: component.invoiceDownloadApiUrl,
        invoiceDownloadApiUrlMethod: component.invoiceDownloadApiUrlMethod,
        invoiceDownloadApiUrlParamsList: component.invoiceDownloadApiUrlParamsList,
        invoiceUploadApiUrl: component.invoiceUploadApiUrl,
        invoiceUploadApiUrlMethod: component.invoiceUploadApiUrlMethod,
        invoiceUploadApiUrlParamsList: component.invoiceUploadApiUrlParamsList,
        invoiceUploadApiUrlPayloadParamsList: component.invoiceUploadApiUrlPayloadParamsList
    });

    let debouncedApiCallHandler = useDebouncedCallback(apiCallHandler, 100);


    const textFields = formInvoiceFields.invoiceSummaryList?.filter((item) => item.invoiceSummaryFieldType === A8Utils.formBuilderFieldNames.text)
    const formFields = formInvoiceFields.invoiceSummaryList?.filter((item) => item.invoiceSummaryFieldType !== A8Utils.formBuilderFieldNames.text)

    const uploadProps = {
        multiple: false,
        maxCount: 1,
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            if (!fileTypes.includes(file.type)) {
                message.error(A8Utils.messages.invoiceUploadFileFormatError);
                return false;
            } else {
                if (fileList.length === 1) {
                    setFileList([file]);
                    return false;
                }
                if (fileList.length === 0) {
                    setFileList([...fileList, file]);
                    return false;
                }
            }
        },
        fileList,
    };

    const validateInvoiceNumber = (_, value) => {
        if (!value || A8Utils.regularExpressions.invoiceNumber.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(A8Utils.messages.invoiceNumber));
    };

    const handleSubmit = (e) => {
        form.validateFields()
            .then((values) => {
                invoiceUploadApiCallHandler(values)
            })
            .catch((errorInfo) => { });
    };

    const handleDownloadSubmit = (e) => {
        form.validateFields()
            .then((values) => {
                invoiceDownloadApiCallHandler(values)
            })
            .catch((errorInfo) => { });
    }

    function invoiceUploadApiCall(url, formData) {
        const token = localStorage.getItem('token');
        const tokenType = localStorage.getItem('tokenType')
        const modifiedConfig = {
            headers: {
                // 'Content-Type': 'multipart/form-data',
                ...(token && tokenType ? { Authorization: `${tokenType} ${token}` } : {}),
            },
        };

        fetch(url, {
            headers: modifiedConfig?.headers,
            body: formData,
            method: formInvoiceFields.invoiceUploadApiUrlMethod
        })
            .then((res) => res.json())
            .then((data) => {
                console.log("invoice:::uploadresponse:::", data)
                setFileList([])
            })
    }

    function invoiceUploadApiCallHandler(formVlaues) {
        let apiUrlParams = {}
        formInvoiceFields.invoiceUploadApiUrlParamsList.forEach((item) => {
            if (item.invoiceUploadApiUrlTarget === A8Utils.formBuilderFieldNames.submission) {
                apiUrlParams[item.invoiceUploadApiUrlParameter] = A8Utils.getColumnElementValue(value, item.invoiceUploadApiUrlTargetKey)
            }
            if (item.invoiceUploadApiUrlTarget === A8Utils.formBuilderFieldNames.invoiceData) {
                apiUrlParams[item.invoiceUploadApiUrlParameter] = A8Utils.getColumnElementValue(responseData, item.invoiceUploadApiUrlTargetKey)
            }
        })

        let formData = new FormData()

        let invoiceDetailsObj = {}

        formInvoiceFields.invoiceUploadApiUrlPayloadParamsList.forEach((item) => {
            if (item.invoiceUploadApiUrlTarget === A8Utils.formBuilderFieldNames.invoiceData) {
                invoiceDetailsObj[item.invoiceUploadApiUrlPayloadParameter] = A8Utils.getColumnElementValue(responseData, item.invoiceUploadApiUrlPayloadTargetKey)
            }
            if (item.invoiceUploadApiUrlTarget === A8Utils.formBuilderFieldNames.submission) {
                invoiceDetailsObj[item.invoiceUploadApiUrlPayloadParameter] = A8Utils.getColumnElementValue(value, item.invoiceUploadApiUrlPayloadTargetKey)
                formData.append(item.invoiceUploadApiUrlPayloadParameter,A8Utils.getColumnElementValue(value, item.invoiceUploadApiUrlPayloadTargetKey))
            }
            if (item.invoiceUploadApiUrlTarget === A8Utils.formBuilderFieldNames.formData) {
                let formItemValue = A8Utils.getColumnElementValue(formVlaues, item.invoiceUploadApiUrlPayloadTargetKey, "object")
                if (typeof formItemValue === 'object') {
                    formData.append(item.invoiceUploadApiUrlPayloadParameter, formItemValue.file)
                } else {
                    invoiceDetailsObj[item.invoiceUploadApiUrlPayloadParameter] = formItemValue
                }
            }
        })
        let invoiceDetails = new Blob(
            [JSON.stringify(invoiceDetailsObj)],
            {
                type: "application/json",
            }
        )

        // formData.append("invoiceDetails", invoiceDetails)

        if (formInvoiceFields.invoiceUploadApiUrl) {
            let url = A8Utils.getAPiUrl(formInvoiceFields.invoiceUploadApiUrl, apiUrlParams)
            invoiceUploadApiCall(url, formData)
        }

    }

    function invoiceDownloadApiCallHandler(formValues) {
        let apiUrlParams = {}
        let payLoad = {}
        component.invoiceDownloadApiUrlPayloadParamsList.forEach((item, index) => {
            if (item.invoiceDownloadApiUrlTarget === A8Utils.formBuilderFieldNames.invoiceData) {
                payLoad[item.invoiceDownloadApiUrlPayloadParameter] = A8Utils.getColumnElementValue(responseData, item.invoiceDownloadApiUrlPayloadTargetKey)
            }
            if (item.invoiceDownloadApiUrlTarget === A8Utils.formBuilderFieldNames.submission) {
                payLoad[item.invoiceDownloadApiUrlPayloadParameter] = A8Utils.getColumnElementValue(value, item.invoiceDownloadApiUrlPayloadTargetKey)
            }
            if (item.invoiceDownloadApiUrlTarget === A8Utils.formBuilderFieldNames.formData) {
                payLoad[item.invoiceDownloadApiUrlPayloadParameter] = A8Utils.getColumnElementValue(formValues, item.invoiceDownloadApiUrlPayloadTargetKey)
            }
            if (item.invoiceDownloadApiUrlTarget === A8Utils.formBuilderFieldNames.True) {
                payLoad[item.invoiceDownloadApiUrlPayloadParameter] = item.invoiceDownloadApiUrlTarget
            }
        })

        formInvoiceFields.invoiceDownloadApiUrlParamsList.forEach((item) => {
            if (item.invoiceDownloadApiUrlTarget === A8Utils.formBuilderFieldNames.submission) {
                apiUrlParams[item.invoiceDownloadApiUrlParameter] = A8Utils.getColumnElementValue(value, item.invoiceDownloadApiUrlTargetKey)
            }
            if (item.invoiceDownloadApiUrlTarget === A8Utils.formBuilderFieldNames.invoiceData) {
                apiUrlParams[item.invoiceDownloadApiUrlParameter] = A8Utils.getColumnElementValue(responseData, item.invoiceDownloadApiUrlTargetKey)
            }

            if (formInvoiceFields.invoiceDownloadApiUrl) {
                let url = A8Utils.getAPiUrl(formInvoiceFields.invoiceDownloadApiUrl, apiUrlParams)
                const token = localStorage.getItem('token');
                const tokenType = localStorage.getItem('tokenType')
                const modifiedConfig = {
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        ...(token && tokenType ? { Authorization: `${tokenType} ${token}` } : {}),
                    },
                };

                let fetchBody = {
                    method: formInvoiceFields.invoiceDownloadApiUrlMethod,
                    headers: modifiedConfig?.headers,
                }
                if (formInvoiceFields.invoiceDownloadApiUrlMethod === A8Utils.formBuilderFieldNames.post) {
                    fetchBody["body"] = JSON.stringify(payLoad);
                }

                fetch(url, fetchBody)
                    .then((response) => {
                        let header = response.headers.get("Content-Disposition")
                        let parts = header.split(';');
                        let fileName = parts[1].split('=')[1];
                        return response.blob().then(blob => ({ blob, filename: fileName }));
                    })
                    .then(({ blob, filename }) => {
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", filename);
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                    })
                    .catch((error) => {

                    })
            }
        })
    }

    function apiCallHandler() {
        if (isBuilder) {
            return
        }
        if (value) {
            let apiUrlParams = {};
            formInvoiceFields.invoiceSummaryApiUrlParamsList?.forEach((item) => {
                apiUrlParams[item.invoiceSummaryApiUrlParameter] = A8Utils.getColumnElementValue(value, item.invoiceSummaryApiUrlTargetKey)
            })
            if (formInvoiceFields.invoiceSummaryApiUrl) {
                let url = A8Utils.getAPiUrl(formInvoiceFields.invoiceSummaryApiUrl, apiUrlParams)
                const token = localStorage.getItem('token');
                const tokenType = localStorage.getItem('tokenType')
                const modifiedConfig = {
                    headers: {
                        'Accept': 'application/json, text/plain, */*',
                        'Content-Type': 'application/json',
                        ...(token && tokenType ? { Authorization: `${tokenType} ${token}` } : {}),
                    },
                };


                fetch(url, {
                    method: formInvoiceFields.invoiceSummaryApiUrlMethod,
                    headers: modifiedConfig?.headers,
                })

                    .then((res) => res.json())
                    .then((json) => {
                        let mappedResponse = A8Utils.getColumnElementValue(json, formInvoiceFields.invoiceSummaryApiResponseDataKey, "object");
                        setResponseData(mappedResponse)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }
    }

    useEffect(() => {
        formFields?.forEach((item) => {
            if (item.invoiceSummaryFieldType === A8Utils.formBuilderFieldNames.input) {
                let formInputItemValue = A8Utils.getColumnElementValue(responseData, item.invoiceSummaryValueKey)
                let defaultvalue = {}
                defaultvalue[item.invoiceSummaryValueKey] = formInputItemValue
                form.setFieldsValue(defaultvalue)
            }
        })
    }, [responseData])

    useEffect(() => {
        if (isBuilder) {
            setResponseData(invoiceSummaryDataDummy)
        }
        else {
            debouncedApiCallHandler()
        }
    }, [])

    useEffect(() => {
        let state = true;
        if (isBuilder) {
            state = true;
        }
        if (value['oid']) {
            state = true;
        }
        setReady(state)
    }, [value['oid']])

    if (!ready) {
        return <>Loadding!!!!</>
    }

    return (
        <>
            <hr />
            <div className='invoice-card'>
                <Row
                    className='invoice-labels'
                    gutter={{
                        xs: 8,
                        sm: 16,
                        md: 24,
                        lg: 32,
                    }}
                >
                    {
                        textFields?.map((item, index) => {
                            return (
                                <>
                                    <Col key={index} className="gutter-row" span={6}>
                                        <div className=''>
                                            <span className='invoice-summary-data-label'>{item.invoiceSummaryLabelName}: </span>
                                            <span>{A8Utils.getColumnElementValue(responseData, item.invoiceSummaryValueKey)}</span>
                                        </div>
                                    </Col>
                                </>
                            )
                        })
                    }
                </Row>
                <Form form={form}>
                    <div className=''>
                        <div className="custom-badge-container">
                            <div className="custom-badge-number">{A8Utils.labels.one}</div>
                            <div className="custom-badge-label">{A8Utils.labels.downloadInvoice}</div>
                        </div>
                        <div className='form-flex'>
                            {
                                formFields?.map((item, index) => {
                                    switch (item.invoiceSummaryFieldType) {
                                        case A8Utils.formBuilderFieldNames.input:
                                            return (
                                                <>
                                                    <div key={index} className='field-floating-label'>
                                                        <label className="input-label">{A8Utils.labels.agencyInvoice}</label>
                                                        <FormItem
                                                            name="agencyInvoice"
                                                            rules={[{ required: true, message: A8Utils.labels.agencyInvoiceValidation }, { validator: validateInvoiceNumber }]}
                                                        >
                                                            <Input
                                                                placeholder={`${A8Utils.labels.enter} ${item.invoiceSummaryLabelName}`}
                                                                size="large"
                                                            // value={A8Utils.getColumnElementValue(responseData, item.invoiceSummaryValueKey) !== "" ? A8Utils.getColumnElementValue(responseData, item.invoiceSummaryValueKey) : undefined}
                                                            />
                                                        </FormItem>
                                                    </div>
                                                </>
                                            )
                                        case A8Utils.formBuilderFieldNames.download:
                                            return (
                                                <Button
                                                    type="primary"
                                                    size='large'
                                                    icon={<DownloadOutlined />}
                                                    className="btn-positive ml-2"
                                                    onClick={() => handleDownloadSubmit()}
                                                >
                                                    {item.invoiceSummaryLabelName}
                                                </Button>
                                            )
                                        default:
                                            break;
                                    }
                                })
                            }
                        </div>
                        {
                            formFields?.map((item, index) => {
                                if (item.invoiceSummaryFieldType === A8Utils.formBuilderFieldNames.upload) {
                                    return (
                                        <>
                                            <div key={index} className='form-item-upload'>
                                                <div className="custom-badge-container">
                                                    <div className="custom-badge-number">{A8Utils.labels.two}</div>
                                                    <div className="custom-badge-label">{A8Utils.labels.uploadInvoice}</div>
                                                </div>
                                                <div className='field-floating-label'>
                                                    <label className="input-label">{A8Utils.labels.uploadInvoice}</label>
                                                    <FormItem
                                                        name="invoiceFile"
                                                    >
                                                        <Dragger {...uploadProps}>
                                                            <p className="ant-upload-drag-icon">
                                                                <InboxOutlined />
                                                            </p>
                                                            <p className="ant-upload-text">{A8Utils.labels.uploadComponentTitle}</p>
                                                            <p className="ant-upload-hint">
                                                                {A8Utils.labels.uploadComponentSubTitle} - {fileExtension.map((item, key) => {
                                                                    return (
                                                                        <>
                                                                            {item}
                                                                            {fileExtension.length - 1 !== key ? ", " : ""}
                                                                        </>
                                                                    )
                                                                })}
                                                            </p>
                                                        </Dragger>
                                                    </FormItem>
                                                </div>
                                            </div>
                                            <div className='form-item-submit'>
                                                <Button
                                                    type='primary'
                                                    size='large'
                                                    className='btn-positive'
                                                    disabled={fileList.length === 0}
                                                    onClick={() => handleSubmit()}
                                                >
                                                    {A8Utils.labels.upload}
                                                </Button>
                                            </div>
                                        </>
                                    )
                                }
                            })
                        }
                    </div>
                </Form>
            </div>
        </>
    )
}

export default GridInvoiceSummaryCustomComponent