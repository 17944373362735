const columnList = [
    {
        columnName: '#',
        columnKey: 'id'
    },
    {
        columnName: 'Contract',
        columnKey: 'contract'
    },
    {
        columnName: 'Customer Name',
        columnKey: 'customerName'
    },
    {
        columnName: 'Elligible Amount',
        columnKey: 'pos'
    },
    {
        columnName: 'Payout Amount',
        columnKey: 'totalPayout'
    },
    {
        columnName: 'Adjustment',
        columnKey: 'adjustmentAmount'
    },
];

const columnData = [
    {
        "id": 1,
        "contract": {
            "id": "TCFCE0310000012445051"
        },
        "customerName": "OSS CONSTRUCTION PRIVATE LIMITED",
        "pos": 7606978.0,
        "totalPayout": 54648.59,
        "bucket": null,
        "bucketStatus": null,
        "processInstanceKey": null,
        "state": null,
        "transactionID": 60,
        "adjustmentAmount": 0.0,
        "adjustment": {
            "id": null,
            "amount": 0.0,
            "reason": "",
            "remarks": "",
            "paymentPartyCalcId": null,
            "fileLocation": ""
        },
        "product": {
            "id": {
                "name": "CVC"
            }
        },
        "averageDailyBalance": null,
        "processingFees": null,
        "sanctionedAmount": null,
        "pdd": false,
        "finalPayout": null,
        "hundredPerPayout": null
    }, {
        "id": 2,
        "contract": {
            "id": "TCFCE0310000012445051"
        },
        "customerName": "OSS CONSTRUCTION PRIVATE LIMITED",
        "pos": 1.0580258E7,
        "totalPayout": 76057.57,
        "bucket": null,
        "bucketStatus": null,
        "processInstanceKey": null,
        "state": null,
        "transactionID": 61,
        "adjustmentAmount": 0.0,
        "adjustment": {
            "id": null,
            "amount": 0.0,
            "reason": "",
            "remarks": "",
            "paymentPartyCalcId": null,
            "fileLocation": ""
        },
        "product": {
            "id": {
                "name": "CVC"
            }
        },
        "averageDailyBalance": null,
        "processingFees": null,
        "sanctionedAmount": null,
        "pdd": false,
        "finalPayout": null,
        "hundredPerPayout": null
    }
]

const invoiceColumnList = [
    {
        columnName: '#',
        columnKey: 'id'
    },
    {
        columnName: 'System Invoice',
        columnKey: 'systemInvoice'
    },
    {
        columnName: 'Agency Invoice',
        columnKey: 'agencyInvoice'
    },
    {
        columnName: 'Amount',
        columnKey: 'amount'
    },
    {
        columnName: 'State',
        columnKey: 'state'
    },
    {
        columnName: 'Invoice Date',
        columnKey: 'invoiceDate'
    },
    {
        columnName: 'Payout Month',
        columnKey: 'payoutMonth'
    },
    {
        columnName: 'UTR Number',
        columnKey: 'utrNumber'
    },
    {
        columnName: 'Payment Amount',
        columnKey: 'paymentAmount'
    },
    {
        columnName: 'Payment Date',
        columnKey: 'paymentDate'
    },
    {
        columnName: 'Download Invoice',
        columnKey: 'downloadInvoice'
    },
    {
        columnName: 'Upload Invoice',
        columnKey: 'uploadInvoice'
    },
    {
        columnName: 'Uploaded Invoice',
        columnKey: 'uploadedInvoice'
    },
];

const invoiceColumnData = [
    {
        "systemInvoice": "ABCD/1234/DE56",
        "agencyInvoice": "",
        "amount": 2400,
        "state": "Maharashtra",
        "invoiceDate": "2024-03-21 02:47:46",
        "payoutMonth": "April",
        "utrNumber": "123789",
        "paymentAmount": 2450,
        "paymentDate": "2024-04-30 12:09:20",
    },
    {
        "systemInvoice": "EFGH/5678/AB90",
        "agencyInvoice": "999111",
        "amount": 4000,
        "state": "Telangan",
        "invoiceDate": "2024-03-21 02:47:46",
        "payoutMonth": "May",
        "utrNumber": "147298",
        "paymentAmount": 4500,
        "paymentDate": "2024-04-30 12:09:20",
    },
    {
        "systemInvoice": "ABCD/1234",
        "agencyInvoice": "",
        "amount": 2420,
        "state": "Maharashtra",
        "invoiceDate": "2024-03-21 02:47:46",
        "payoutMonth": "April",
        "utrNumber": "123789",
        "paymentAmount": 2550,
        "paymentDate": "2024-04-30 12:09:20",
    },
]

const treeData = {
    "Branch_Name": "289 - TCFSL MUMBA",
    "State_Code": "27",
    "State_For_Gst": "Maharashtra",
    "Region": "West",
    "City_Category": "A",
    "Gstin": "27AADCP9147P1ZN",
    "Registered_Branch_Address": "11th Floor, 1101, 1102, Peninsula Business Park, Tower A,Ganpat Rao Kadam Marg, Lower Parel, Mumbai Suburban,Maharashtra, 400013",
    "Action": null,
    "BRANCH_REGION": "WEST ",
    "BRANCH_NAME": "289 - TCL MUMBA",
    "BRANCH_STATE": "Maharashtra",
    "BRANCH_CITY": "Mumbai",
    "LOAN_ID": "TCFCV0289000012600485",
    "GROUP_NAME": "S N V G LOGISTICS PRIVATE LIMITED MUMBAI",
    "NEO_CIF_ID": "GLBCUST00014608998",
    "OPPORTUNITY_ID": "10438810",
    "CUSTOMER_NAME": "S.N.V.G. LOGISTICS PRIVATE LIMITED",
    "ASSET_MODEL": "TATA SIGNA 3518 60WB CAB 28_CV",
    "MIDDLE_NAME": null,
    "APPLICATION_NO": "APPL14366833",
    "ASSET_LEVEL": "L1",
    "Asset_Category_Code": "COCV_001",
    "ASSET_CATEGORY": "M AND HCV",
    "ASSET_VARIANT": null,
    "Product_Code": "CVCO001",
    "PRODUCT_NAME": "CORPORATE - CV",
    "Sub_Product_code": "Repurchase",
    "SUB_PRODUCT_NAME": "V_Repurchase_2021-22",
    "DEALER": "CUSTOMER 01",
    "DEALER_CODE": "CUST01",
    "FUNDED_FOR": "FBV",
    "REGISTRATION_NUMBER": "MH43CE5873",
    "INVOICE_NO": null,
    "CHASIS_NUMBER": "MAT503059JAK22732",
    "MACHINE_SERIAL_NO": null,
    "MSP_Code": null,
    "MSP_name": null,
    "DSA_CODE": "30199",
    "DSA_NAME": "SHUBHANGI JAMNESH MARNE - DSA - CEQ",
    "PAYMENT_MODE": "Electronic Fund Transfer",
    "CHEQUE_NUMBER": null,
    "FI_AGENCY_CODE": null,
    "FI_AGENCY_NAME": null,
    "RELATIONSHIP_MANAGER": "Krushnat Satre",
    "EMPLOYEE_CODE": "741117",
    "PAYABLE_TO": "Primary Applicant",
    "CHEQUE_IN_FAVOR_OF": "S.N.V.G. LOGISTICS PRIVATE LIMITED",
    "VALUATION_AGENCY_CODE": null,
    "VALUATION_AGENCY_NAME": null,
    "INVOICE_DATE": "2024-08-21T18:30:00.000Z",
    "INVOICE_VALUE": 152,
    "AMT_FINANCED": 2100000,
    "NET_DISBURSAL_AMOUNT": 2079400,
    "TENURE": 40,
    "EFFECTIVE_ROI": 12.54,
    "BUSS_IRR": 12.54,
    "LOAN_BOOKING_DATE": "2023-12-30T18:30:00.000Z",
    "DISBURSAL_DATE": "2023-12-30T18:30:00.000Z",
    "DEALER_SUBVENTION": 0,
    "MANUFACTURE_SUBVENTION": 0,
    "CREDIT_DAYS_CHARGE": 0,
    "CREDIT_PERIOD": 0,
    "PROCESSING_FEES": 5000,
    "STAMP_CHARGES": 11100,
    "SERVICE_CHARGE": 0,
    "DOWN_PAYMENT_AMOUNT": 478680,
    "ADV_EMI_AMT": 0,
    "SECURITY_DEPOSIT": 0,
    "CGTMSE_CHARGE": 0,
    "DOCUMENT_CHARGES": 2500,
    "AUTHORIZATION_DATE": "2023-12-30T18:30:00.000Z",
    "CUSTOMER_SEGMENT": null,
    "VALUATION_AMOUNT": 0,
    "MANUFACTURER": "TATA MOTORS LTD. - CEQ",
    "MANUFACTURER_CODE": "5101205",
    "HDFC_INSURANCE": 0,
    "TATA_AIG_ASSET_INSURANCE": 0,
    "TATA_HOME_GUARD_PLUS_INSURANCE": 0,
    "SUBLOAN_ID": "9837298",
    "RATE_TYPE": "Fixed",
    "CURRENT_RATE_TYPE": "Fixed",
    "FIRST_EMI_DATE": "2024-02-04T18:30:00.000Z",
    "MORATORIUM_START_DATE": "2023-09-13T18:30:00.000Z",
    "MORATORIUM_END_DATE": "2023-10-14T18:30:00.000Z",
    "Branch_Code": "289",
    "id": 1021,
    "created_at": "2024-09-13T02:35:33.000Z",
    "batch_id": 246,
    "transaction_date": "2024-03-31T18:30:00.000Z",
    "Fees_Without_GST": 4237.28813559322,
    "WarN": 1053359999.9999999,
    "WarD": 84000000,
    "WiRR": 12.2644584411903,
    "applicableGrids": [
        {
            "rule_plan": "Commercial_Loan_Rule_Plan",
            "name": "Payout Group",
            "rules": [
                {
                    "unique_id": "066e293a-98fe-7014-8000-55d51ed970ce",
                    "dmnDecisionId": "DSA_CV_Grid",
                    "variable": "Grid_Payout_Amount",
                    "version": 47,
                    "finalOutputVariable": "Contract Based Payout"
                },
                {
                    "unique_id": "066e2961-b2e0-7365-8000-05276b303a33",
                    "dmnDecisionId": "DSA_CV_Volume_Grid",
                    "variable": "Vol_Payout_Perc",
                    "version": 107,
                    "finalOutputVariable": "Volume Payout"

                }
            ]
        }
    ],
    "serial": 112,
    "rowsCount": "319",
    "User": "30199",
    "Branch": "289",
    "composite_key": "30199:_:289",
    "composite_key_order": "User:_:Branch",
    "Contract Based Payout": 0,
    "Grid_Payout_Percentage": "0",
    "Grid_Payout_Amount": "0",
    "Volume Payout": 0,
    "Vol_Payout_Perc": "0",
    "Max Payable": 0,
    "Final Payout": 0,
    "adjustment_amount": null,
    "unified_id": "066e28fb-0a34-7dee-8000-527bface3c3c",
    "payment_process_id": "066e3f28-e051-72e0-8000-9984677c6108",
    "iprogram_id": "066e2989-3222-7b27-8000-0962cbfecb30",
    "frequency_id": "066dfd6b-e0b2-7faa-8000-be346b127758",
    "frequency": "monthly",
    "start_date": "2024-04-01",
    "end_date": "2024-04-30",
    "organization_id": "066ded05-11e0-77b5-8000-e8a4dbda6f17",
    "calculation_details_id": "066e3f28-e06b-7349-8000-07857b510e2d",
    "final_output_variable_name": "Final Payout",
    "decisionOutputs": [
        {
            "decisionId": "DSA_CV_Grid",
            "decisionName": "DSA_CV_Grid",
            "decisionVersion": 47,
            "decisionOutput": "{\"Grid_Payout_Percentage\":0,\"Grid_Payout_Amount\":0}",
            "ruleIndex": 28,
            "decisionInputs": [
                {
                    "BUSS_IRR": "12.54"
                },
                {
                    "Sub_Product_code": "\"Repurchase\""
                }
            ],
            "rulePlan": "Commercial_Loan_Rule_Plan",
            "feel_expression_formula": {
                "formula": "(((AMT_FINANCED * Grid_Payout_Percentage) / 100) + Grid_Payout_Amount)",
                "output": 0
            },
            "Contract Based Payout": 0,
            "inputs": [
                {
                    "AMT_FINANCED": "2100000"
                },
                {
                    "Grid_Payout_Amount": "0"
                },
                {
                    "Grid_Payout_Percentage": "0"
                }
            ]
        },
        {
            "decisionId": "DSA_CV_Volume_Grid",
            "decisionName": "DSA_CV_Volume_Grid",
            "decisionVersion": 107,
            "decisionOutput": "0",
            "ruleIndex": 1,
            "decisionInputs": [
                {
                    "WiRR": "12.2644584411903"
                },
                {
                    "AMT_FINANCED": "2100000"
                }
            ],
            "rulePlan": "Commercial_Loan_Rule_Plan",
            "feel_expression_formula": {
                "formula": "((AMT_FINANCED * Vol_Payout_Perc) / 100)",
                "output": 0
            },
            "Volume Payout": 0,
            "inputs": [
                {
                    "AMT_FINANCED": "2100000"
                },
                {
                    "Vol_Payout_Perc": "0"
                }
            ]
        },
        {
            "decisionId": "NA",
            "decisionName": "NA",
            "decisionVersion": 0,
            "decisionOutput": 0,
            "ruleIndex": -1,
            "decisionInputs": [],
            "ElseJsonLogic_formula": {
                "formula": "(Contract Based Payout + Volume Payout)",
                "output": 0
            },
            "Max Payable": 0,
            "inputs": [
                {
                    "Volume Payout": "0"
                },
                {
                    "Contract Based Payout": "0"
                }
            ]
        }
    ],
    "final_output_formula": {
        "formula": "Max Payable",
        "output": 0
    }
}

export { columnList, columnData, invoiceColumnList, invoiceColumnData, treeData }